import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  ResultsSection,
  SingleReportPage,
  SubdomainResultsSection,
} from '@/features';
import { BrowsePageProps, PageType } from '@/types/page';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

const ROOT = makeRootClassName('BrowsePage');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  pageType: PageType.BROWSE_ALL,
} as const;

function BrowsePage(props: BrowsePageProps & StyleProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const renderPage = () => {
    switch (p.pageType) {
      case PageType.BROWSE_ALL:
        return <ResultsSection pageType={PageType.BROWSE_ALL} />;
      case PageType.BROWSE_CHAIN:
        return (
          <ResultsSection pageType={PageType.BROWSE_CHAIN} chain={p.chain} />
        );
      case PageType.BROWSE_CATEGORY:
        return (
          <ResultsSection
            pageType={PageType.BROWSE_CATEGORY}
            scamCategory={p.category}
          />
        );
      case PageType.REPORT_BY_ID:
        return (
          <SingleReportPage
            reportId={p.reportId}
            pageType={PageType.REPORT_BY_ID}
          />
        );
      case PageType.MY_FEED:
        return (
          <SingleReportPage
            reportId={p.reportId}
            orgId={p.orgId}
            pageType={PageType.MY_FEED}
          />
        );
      case PageType.SUBDOMAIN_FEED: {
        return <SubdomainResultsSection orgId={p.orgId} orgSlug={p.orgSlug} />;
      }
    }
  };

  return <div className={clsx(ROOT, p.className)}>{renderPage()}</div>;
}

export default BrowsePage;
