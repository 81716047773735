import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { ReportsByOrganizationFilter } from '@/generated/graphql';
import { PageType } from '@/types/page';
import useOrganization from '../subdomain-page/hooks/useOrganization';
import ResultsSection from './ResultsSection';

type SubdomainResultsSectionProps = {
  orgId?: string;
  orgSlug?: string;
};

function SubdomainResultsSection(
  props: SubdomainResultsSectionProps
): ReactElement {
  const { push, asPath, query } = useRouter();

  const { organization, loading } = useOrganization({
    orgId: props.orgId,
    orgSlug: props.orgSlug,
  });

  const org = organization
    ? {
        id: organization.id,
        filterBy: ReportsByOrganizationFilter.PUBLIC_KEYWORDS,
      }
    : undefined;

  const handleSelectPage = (page: number) => {
    // @HACK: we need to do this due to https://github.com/47ng/next-usequerystate/issues/250
    if (asPath.includes('org-by-slug')) {
      push({ query: { ...query, page: page - 1 } });
      return;
    }

    push(`/?page=${page - 1}`);
  };

  return (
    <ResultsSection
      isLoading={loading}
      onSelectPage={handleSelectPage}
      pageType={PageType.SUBDOMAIN_FEED}
      org={org}
    />
  );
}

export default SubdomainResultsSection;
