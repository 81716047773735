import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Text } from '@/components';

export type ReportKeywordsProps = StyleProps & {
  keywords: string[];
};

const ROOT = makeRootClassName('ReportKeywords');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function ReportKeywords(props: ReportKeywordsProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Text type="h4" className={el`title`}>
        Keywords:
      </Text>

      <div className={el`keywords`}>
        {props.keywords.map((keyword, index) => (
          <Text
            key={`${keyword}-${index}`}
            type="body-lg"
            className={el`keyword`}
          >
            {keyword}
          </Text>
        ))}
      </div>
    </div>
  );
}

export default ReportKeywords;
